<template>
  <!-- Our Agents Section Start -->    
<div class="contact-section section pt-110 pt-md-90 pt-sm-70 pt-xs-60 pb-110 pb-md-90 pb-sm-70 pb-xs-60">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-us-wrap">
                    <div class="contact-title pb-30">
                            <a id="contact"/>
                        <h4>Localízanos</h4>
                        <p>Las oficinas centrales de CitySol se encuentra en la siguiente dirección, ven a vernos, llama o envíanos un mensaje </p>
                    </div>
                    
                    <div class="contact-info">
                        <ul>
                            <li>
                                <div class="contact-text d-flex align-items-center">
                                    <i class="glyph-icon flaticon-placeholder"></i> 
                                    <p>RONDA PARQUE UNIVERSIDAD S/N <br>ESPINARDO 30100 MURCIA</p>
                                </div>
                            </li>
                            <li>
                                <div class="contact-text d-flex align-items-center">
                                    <i class="glyph-icon flaticon-call"></i> 
                                    <p>
                                        <a href="tel:+34628795606" ><v-icon>mdi-phone</v-icon> 628 795 606</a><br>
                                        <a href="tel:+34697726362" ><v-icon>mdi-phone</v-icon> 697 726 362</a><br>
                                        <a href="tel:+34868499994" ><v-icon>mdi-phone</v-icon> 868 499 994</a>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="contact-text d-flex align-items-center">
                                    <i class="glyph-icon flaticon-earth"></i>
                                    <p>
                                        Correo de contacto: <span><a href="mailto:info@citysol.es">info@citysol.es</a></span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>   
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="contact-us-wrap">
                    <h4>Habla con nosotros</h4>
                       
                     <div class="contact-form">
                     
                            <div class="row row-10">
                                <div class="col-md-6 col-12 mb-30"><v-text-field v-model="name" solo class="no-border-radius" placeholder="Nombre"></v-text-field></div>
                                <div class="col-md-6 col-12 mb-30"><v-text-field v-model="email" solo class="no-border-radius" type="email" placeholder="Email"></v-text-field></div>
                                <div class="col-12 mb-30"><v-textarea name="msg"  label="Mensaje" solo v-model="msg"></v-textarea></div>
                                <div class="col-12"><button class="btn send-btn btn-circle" @click="sendMail">Enviar</button></div>
                            </div>
                      
                        <p class="form-messege" v-html="formMessage"></p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    
</div><!-- Our Agents Section End -->  
</template>
<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name:"contact",
  data(){
      return {
          name:"",
          email: "",
          msg: "",
          subject: "Contacto web Cytisol",
          toName: "CitySol",
          toEmail: "web@notin.net",
          formMessage:""
      }
  },
  methods:{
      async sendMail(){
          try{
            let r = await axios.post('https://banner.notin.es/Landings/parser/email.php', {name: this.name , email: this.email, msg: this.msg, subject: this.subject, toname: this.toName, toemail: this.toEmail})
            console.log(r)
          }catch(e){
            this.formMessage = '<span style="color:red;">Ocurrio un error enviando el email, por favor intentelo de nuevo mas tarde.</span>'    
          }
          //console.log(r)
          //this.formMessage = '<span style="color:red;">Ocurrio un error enviando el email, por favor intentelo de nuevo mas tarde.</span>'    
          //this.formMessage = r
          //return r
      }
  }
}
</script>
<style>
.no-border-radius, .no-border-radius input{
    border-radius: 0px !important;
}
</style>
